import React, {useState} from 'react';
import { navigate } from 'gatsby-link';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useSpeechRecognition } from 'react-speech-kit';
import { IconButton, Tooltip } from '@material-ui/core';
import MicIcon from '@material-ui/icons/Mic';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function ContactForm () {

  const supportsSpeechRecognition = typeof window !== 'undefined' && typeof window.SpeechRecognition !== 'undefined';

  const [formState, setFormState] = useState({})
  const [text, setText] = useState('');
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setText(text + ' ' + result);
    },
  });
  // const [isValidated, setIsValidated] = useState(false);

  const handleChange = e => {
    setFormState({[e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formState,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }
  const classes = useStyles();

  return (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <h2 className={classes.title}>Work with us</h2>
            <h4 className={classes.description}>
              Request access for demo website here. We will send you credentials as soon as possible.
            </h4>
            <form
              name="contact"
              method="post"
              action="/contact/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <div hidden>
                <label>
                  Don’t fill this out:{' '}
                  <input name="bot-field" onChange={handleChange} />
                </label>
              </div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Name*"
                    onChange={handleChange}
                    id={'name'}
                    required
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{required: true}}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Email*"
                    id="email"
                    onChange={handleChange}
                    required={true}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{required: true}}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={supportsSpeechRecognition ? 11 : 12}>
                  <CustomInput
                    value={text}
                    labelText="Your Message*"
                    onChange={handleChange}
                    id={'message'}
                    required={true}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      required: true,
                      value: text,
                      onChange: (event) => setText(event.target.value)
                    }}
                  />
                </GridItem>

                {supportsSpeechRecognition &&
                  <GridItem xs={12} sm={12} md={1}>
                    <Tooltip title="Hold this button and speak" arrow placement={'top'}>
                      <IconButton aria-label="speak" onMouseDown={listen} onMouseUp={stop}><MicIcon/></IconButton>
                    </Tooltip>
                    {listening && <h4 className={classes.description}>Listening...</h4>}
                  </GridItem>
                }
                <GridItem xs={12} sm={12} md={12}>
                  <Button type={'submit'} color="primary">Send Message</Button>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    </>
  )
}